import type { AuthContextType } from '@context/auth.context';
import type { Role } from '@interfaces/role';
import type { LoaderFunction } from 'react-router-dom';
import { redirect } from 'react-router-dom';

export const createAuthorizedLoader = (
  requiredRoles: Role[],
  auth: AuthContextType,
  loaderFunction: (auth: AuthContextType) => LoaderFunction,
) => {
  const hasRole = auth.hasRole;
  const isAuthorized = requiredRoles.some((role) => hasRole(role));
  if (!isAuthorized) {
    return async () => redirect('/');
  }

  return loaderFunction(auth);
};
